var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { Slider, FormHelperText, Tooltip } from '@mui/material';
// translation
import { useLocales } from '../../locales';
export default function RHFSlider(_a) {
    var name = _a.name, label = _a.label, helperText = _a.helperText, other = __rest(_a, ["name", "label", "helperText"]);
    var control = useFormContext().control;
    var translate = useLocales().translate;
    return (_jsx(Controller, { name: name, control: control, render: function (_a) {
            var field = _a.field, error = _a.fieldState.error;
            return (_jsxs(_Fragment, { children: [_jsx(Tooltip, __assign({ title: "".concat(translate(label)) }, { children: _jsx(Slider, __assign({}, field, { valueLabelDisplay: "auto" }, other)) })), (!!error || helperText) && (_jsx(FormHelperText, __assign({ error: !!error }, { children: error ? "".concat(translate(error === null || error === void 0 ? void 0 : error.message)) : "".concat(translate(helperText)) })))] }));
        } }));
}
